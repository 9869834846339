import { Grid, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import ResponsivePageHeading from '../components/ResponsivePageHeading';

import './Home.css';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function Home() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Layout title={"Home"}>
      <div style={{ marginBottom: "2em" }}/>
      <ResponsivePageHeading variant="h3">Hello World!</ResponsivePageHeading>
      <ResponsivePageHeading variant="h4">I am Xenphira.</ResponsivePageHeading>
      {/* <div id="landing" style={{height: "10em", display: "block"}} /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <div id="landing" style={{height: windowDimensions.width < 600 ? "20em" : "100%", display: "block"}} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" gutterBottom>
            You may know me from such places as: 1) the internet.
          </Typography>
          <div style={{ marginBottom: "2em" }}/>
          <Typography variant="body1" gutterBottom>
            Welcome to my corner of the web! Here, I will host information about me, contact methods, projects, references, and commissions for which I am waiting.
          </Typography>
          <div style={{ marginBottom: "2em" }}/>
          <Typography variant="body1" gutterBottom>
            Please see the navigation bar at the top to move around the site. If you find there is a bug in how the site works, please feel free to contact me at the email address listed on the about page. Other welcomed communications via email include: inquiries/requests about using my characters' likeness and/or existing art. I also have my social media profiles and messenger handles listed for a means of contact.
          </Typography>
          <div style={{ marginBottom: "2em" }}/>
          <Typography variant="body1" gutterBottom>
            Special thanks to PUNXsimon, the artist who rendered the image of Xen you see here on the homepage. Their work can be found on their <a href="https://twitter.com/PUNXSimon" target="_blank" rel="noreferrer">Twitter</a>.
          </Typography>
        </Grid>
      </Grid>
      
      <div style={{ marginBottom: "2em" }}/>
    </Layout>
  )
}

export default Home