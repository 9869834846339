import { ThemeOptions, createTheme, ThemeProvider } from '@mui/material/styles';
import React, { FC } from "react";

interface Props {
  // any props that come into the component
  children: React.ReactNode;
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#279AE0',
    },
    secondary: {
      main: '#F09FB6',
    },
    error: {
      main: '#E33006',
    },
    warning: {
      main: '#FEDDB5',
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro',
    h1: {
      fontFamily: 'Homemade Apple',
    },
    h2: {
      fontFamily: 'Rock Salt',
    },
    h3: {
      fontFamily: 'Permanent Marker',
    },
    h4: {
      fontFamily: 'Press Start 2P',
    },
  },
};

export const theme: any = createTheme(themeOptions);

export const XenTheme: FC<Props> = ({ children, ...props }) => {
  return <ThemeProvider theme={theme}>
    <React.Fragment>
      {children}
    </React.Fragment>
  </ThemeProvider>
}