import { AppBar, Box, Button, Chip, Toolbar, Typography } from '@mui/material'
// import { IconButton } from '@mui/material'
import React from 'react'
// import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

interface Props {
  title?: string,
  chip?: string,
}

function Nav(props: Props) {
  const pages = [
    { linkText: "Home", path: "/" },
    { linkText: "About", path: "/about" },
    { linkText: "Characters", path: "/characters" },
    { linkText: "Commissions", path: "/commissions" },
    { linkText: "Other", path: "/other" },
  ];

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton> */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {props.title || ""} {props.chip ? <Chip label={props.chip} variant={"outlined"} color="warning" /> : ""}
            </Typography>
            {pages.map(page => 
              <Button
                key={`nav-button-${page.linkText}`}
                component={Link}
                to={page.path}
                color="inherit"
                variant={(window.location.pathname === page.path ? "outlined" : "text")}
              >
                {page.linkText}
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  )
}

export default Nav