import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import ResponsivePageHeading from '../components/ResponsivePageHeading'

import { collection, DocumentData, getDocs } from "firebase/firestore";
import { db } from "../components/FirebaseStuff";
import { Link } from 'react-router-dom';

interface Props {
  nsfw?: boolean
}

function Characters(props: Props) {
  let { nsfw } = props;
  const [characters, setCharacters] = React.useState<DocumentData[]>([]);
  const charsRef = collection(db, "characters");
  useEffect(() => {
    let c: DocumentData[] = [];
    // getDocs(charsRef).then(docs => setCharacters(docs));
    getDocs(charsRef).then(querySnapshot => querySnapshot.forEach(doc => {
      c.push({id: doc.id, data: doc.data()});
    })).finally(() => {
      c.sort((a, b) => a.data.order > b.data.order ? 1 : -1);
      c = c.filter(c => c.data.enabled === undefined || c.data.enabled !== false);
      if (nsfw) {
        c = c.filter(c => c.data.nsfwAllowed === undefined || c.data.nsfwAllowed === true);
      }
      setCharacters(c);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout title={`Characters`} chip={nsfw ? "Spicy" : undefined}>
      <div style={{ marginBottom: "2em" }}/>
      <ResponsivePageHeading variant="h2">Characters{(nsfw ? " (Spicy)" : "")}</ResponsivePageHeading>
      {characters.map(character => (<div key={character.data.shortName}><Link to={`/characters/${character.id}`}>{character.data.shortName}</Link></div>))}
    </Layout>
  )
}

Characters.defaultProps = {
  nsfw: false
} as Partial<Props>;

export default Characters