import { Grid, Typography } from '@mui/material'
import React from 'react'
import Layout from '../components/Layout'
import ResponsivePageHeading from '../components/ResponsivePageHeading'
import { age, electronicmail_addr } from '../consts'

function About() {
  const social_links = [
    {"display": "Twitter", "val": "@xenphira", "url": "https://twitter.com/xenphira"},
    {"display": "FurAffinity", "val": "/user/xenphira", "url": "https://furaffinity.net/user/xenphira"},
    {"display": "Telegram", "val": "@XenphiraChatProxy_Bot", "url": "https://t.me/XenphiraChatProxy_Bot"},
    {"display": "Email", "val": `${electronicmail_addr}`, "url": `mailto:${electronicmail_addr}`},
  ]

  return (
    <Layout title='About'>
      <div style={{ marginBottom: "2em" }}/>
      <ResponsivePageHeading variant="h2">About</ResponsivePageHeading>
      <Typography variant="body1" gutterBottom>
        Xenphira Hollyvine is a {age} year old furry fandom member, 
        musician, technology enthusiast, and, very rarely, artist. 
        Xenphira also goes by the shortened form of her name, Xen. 
      </Typography>
      <ResponsivePageHeading variant="h3">Social Media and Contact Info</ResponsivePageHeading>
      {/* <Grid spacing={2}> */}
        {social_links.map((social_link, index) => 
          <Grid container key={`social-link-${index}`} spacing={2}>
          {/* <span key={`social-link-${index}`}> */}
            <Grid item xs={3}>
              {social_link.display}
            </Grid>
            <Grid item xs={9}>
              <a href={social_link.url} target="_blank" rel="noreferrer">
                {social_link.val}
              </a>
            </Grid>
          {/* </span> */}
          </Grid>
        )}
      {/* </Grid> */}
    </Layout>
  )
}

export default About