import { Container } from '@mui/material';
import React from 'react'
import Nav from './Nav';

interface Props {
  children: React.ReactNode;
  title?: string;
  chip?: string;
}

function Layout(props: Props) {
  const { children } = props;
  return (
    <div>
      <Nav title={props.title} chip={props.chip}/>
      <Container>
        <React.Fragment>
          {children}
        </React.Fragment>
      </Container>
    </div>
  )
}

export default Layout