import { Button, Chip, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import ResponsivePageHeading from '../components/ResponsivePageHeading'

import { DocumentData } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";

import { db, storage } from "../components/FirebaseStuff";
import { ref, getDownloadURL } from "firebase/storage";
import { Link, useParams } from 'react-router-dom';

import aceFlag from "../img/flags/ace.svg"; // eslint-disable-line @typescript-eslint/no-unused-vars
import aroFlag from "../img/flags/aro.svg"; // eslint-disable-line @typescript-eslint/no-unused-vars
import biFlag from "../img/flags/bi.svg"; // eslint-disable-line @typescript-eslint/no-unused-vars
import gayFlag from "../img/flags/new_gay.svg"; // eslint-disable-line @typescript-eslint/no-unused-vars
import lesFlag from "../img/flags/les.svg"; // eslint-disable-line @typescript-eslint/no-unused-vars
import nbFlag from "../img/flags/nb.svg"; // eslint-disable-line @typescript-eslint/no-unused-vars
import panFlag from "../img/flags/pan.svg"; // eslint-disable-line @typescript-eslint/no-unused-vars
import transFlag from "../img/flags/trans.svg"; // eslint-disable-line @typescript-eslint/no-unused-vars
import Page from '../components/Page';

const prideFlags = [
  {name: "ace", flag: aceFlag},
  {name: "aro", flag: aroFlag},
  {name: "bi", flag: biFlag},
  {name: "gay", flag: gayFlag},
  {name: "les", flag: lesFlag},
  {name: "nb", flag: nbFlag},
  {name: "pan", flag: panFlag},
  {name: "trans", flag: transFlag}
]

interface Props {
  nsfw?: boolean
}

function Character(props: Props) {
  let { nsfw } = props;
  // console.log(`Is nsfw? ${nsfw}`);
  let { character } = useParams();
  const [characterData, setCharacterData] = React.useState<DocumentData>();
  const [characterRefSFW, setCharacterRefSFW] = React.useState<string>();
  const [characterRefSFWArtist, setCharacterRefSFWArtist] = React.useState<DocumentData>();
  const [relationshipsChars, setRelationshipsChars] = React.useState<any>();
  const [notFound, setNotFound] = React.useState(false);
  useEffect(() => {
    getDoc(doc(db, "characters", character || "")).then(docu => {
      if(docu.exists()) {
        if(docu.data().enabled !== undefined && docu.data().enabled === false) {
          setNotFound(true);
          return;
        }
        setCharacterData(docu.data());
        if(docu.data().sfwRef) {
          getDownloadURL(ref(storage, docu.data().sfwRef)).then(url => setCharacterRefSFW(url));
          getDoc(doc(db, docu.data().sfwRefBy.path)).then(docu2 => {
            setCharacterRefSFWArtist(docu2.data());
          })
        }
        if(docu.data().info && docu.data().info.relationships) {
          let rels = [...docu.data().info.relationships];
          let allDoneCount = rels.length;
          let currentDoneCount = 0;
          let relData:any[] = [];
          
          rels.forEach(rel => {
            getDoc(doc(db, rel.char.path)).then(docu2 => {
              relData.push({
                ...docu2.data(),
                id: docu2.id,
                description: rel.description,
              });
              currentDoneCount++;
              if(currentDoneCount === allDoneCount) {
                setRelationshipsChars(relData);
              }
            })
          })
        }
        
      } else {
        setNotFound(true);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout title={`Characters ${characterData !== undefined ? " | " + characterData.shortName : ""}`} chip={nsfw ? "Spicy" : undefined}>
      <div style={{ marginBottom: "2em" }}/>
      {notFound ? <div>
        <Page title="XENPHIRA | Character Not Found" />
        <ResponsivePageHeading variant="h2">Character Not Found</ResponsivePageHeading>
      </div> : characterData !== undefined && characterData.nsfwAllowed === false && nsfw ? <div>Nope!</div> :
      (
        <div>
          <Page title={`XENPHIRA | Reference${characterData === undefined ? "" : ": "+characterData?.shortName +(nsfw ? " (Spicy)" : "")}`} />
          <ResponsivePageHeading variant="h2">{characterData !== undefined ? characterData.name : ""}{nsfw ? <Chip label={"NSFW"} variant={"outlined"} color={"warning"} /> : ""}</ResponsivePageHeading>
          {/* <Typography variant="body1" gutterBottom>{JSON.stringify(characterData)}</Typography> */}
          {nsfw ? (
            <Button variant="contained" color="secondary" component={Link} to={`/characters/${character}`}>
              Return to SFW
            </Button>
          ) : ""}
          
          <div style={{ marginBottom: "2em" }}/>

          {/* Pride Flags */}
          {characterData !== undefined ? characterData.flags !== undefined ? <div style={{textAlign: "center"}}>
            {characterData.flags.map((flag: React.Key | string, idx: number) => (<div key={idx}><img alt="" src={prideFlags.filter(i => i.name === flag)[0].flag} width="150vh" /></div>))}
            <div style={{ marginBottom: "2em" }}/>
          </div> : "" : ""}

          {/* Basic Description */}
          <div style={{textAlign: "center"}}>
            {/* Species */}
            {characterData !== undefined ? characterData.species !== undefined ? <div>
              <Typography variant="h3" fontSize={"2em"} gutterBottom>Species: {characterData.species}</Typography>
            </div> : "" : ""}
            {/* Pronouns */}
            {characterData !== undefined ? characterData.pronouns !== undefined ? <div>
              <Typography variant="h3" fontSize={"2em"} gutterBottom>Pronouns: {characterData.pronouns}</Typography>
            </div> : "" : ""}
            {/* Orientation */}
            {characterData !== undefined ? characterData.orientation !== undefined ? <div>
              <Typography variant="h3" fontSize={"2em"} gutterBottom>Orientation: {characterData.orientation}</Typography>
            </div> : "" : ""}
            {/* Age */}
            {/* {characterData !== undefined ? characterData.age !== undefined ? <div>
              <Typography variant="h3" fontSize={"2em"} gutterBottom>Age: {characterData.age}</Typography>
            </div> : "" : ""} */}
            <div style={{ marginBottom: "2em" }}/>
            {/* /Description */}
          </div>

          {/* Colors */}
          {characterData !== undefined ? <div style={{maxWidth: "75%", margin: "0 auto"}}>
            <Grid container spacing={1}>
              {characterData.colors !== undefined ? characterData.colors.map((color: React.Key | null | undefined | any, idx: number) => (<Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={color?.label || idx}>
                <div style={{ backgroundColor: color?.color, textShadow: "1px 1px #000000", width: "100%", height: "100%", borderRadius: "5px", padding: "1em" }}>{color?.label}<br />{color?.color}</div>
              </Grid>)) : ""}
            </Grid>
          </div> : ""}
          
          <div style={{ marginBottom: "2em" }}/>

          {/* SFW Reference */}
          {
            characterRefSFW !== undefined ? 
              <div style={{maxWidth: "75%", margin: "0 auto"}}>
                <img
                  src={characterRefSFW}
                  alt={characterData !== undefined ? characterData.name: ""}
                  style={{ maxWidth: "100%" }}
                />
                {characterRefSFWArtist !== undefined ? <div>
                  <Typography variant="body1" gutterBottom>Reference by <a style={{ textDecoration: "none" }} href={characterRefSFWArtist.primaryLink} target="_blank" rel="noreferrer">{characterRefSFWArtist.name}</a></Typography>
                  <div style={{ marginBottom: "2em" }}/>
                </div> : ""}
              </div>
            : ""
          }

          {/* nsfw info section */}
          {nsfw && characterData !== undefined && characterData.spicyInfo !== undefined ? <div>
            <Grid container spacing={1} justifyContent={"center"} style={{maxWidth: "75%", margin: "0 auto"}}>
              <Typography variant="h3" fontSize={"2em"} gutterBottom>Spicy Details</Typography>
              {characterData.spicyInfo.physical !== undefined ? <Grid container spacing={1} justifyContent={"center"}>
                <Typography variant="h4" fontSize={"1.5em"} gutterBottom>Physical Traits</Typography>
                {characterData.spicyInfo.physical.penis !== undefined ? <Grid container spacing={1} justifyContent={"center"} style={{ marginBottom: "2em" }}>
                  <Grid item><span style={{ fontWeight: "bold" }}>Penis:</span></Grid>
                  {/* <Grid item>{JSON.stringify(characterData.spicyInfo.physical.penis)}</Grid> */}
                  <Grid item>{`${characterData.spicyInfo.physical.penis.lengthInches || "Unknown Length"}", ${characterData.spicyInfo.physical.penis.style || "Unknown Style"}, ${characterData.spicyInfo.physical.penis.intact === undefined ? "Unknown Intact Status" : characterData.spicyInfo.physical.penis.intact ? "intact (uncircumcized)" : "cut (circumcized)"}`}</Grid>
                </Grid> : ""}
              </Grid> : ""}
              {characterData.spicyInfo.kinks !== undefined ? <Grid container spacing={1} justifyContent={"center"}>
              <Typography variant="h4" fontSize={"1.5em"} gutterBottom>Notable Kinks/Fetishes</Typography>
              <Grid container spacing={1} justifyContent={"center"}>
                <ul>
                {characterData.spicyInfo.kinks.map((kink: React.Key | string, idx: number) => (<Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={idx}>
                  <li>{kink}</li>
                </Grid>))}
                </ul>
              </Grid>
              </Grid> : ""}
            </Grid>

            <div style={{ marginBottom: "2em" }}/>
          </div> : ""}

          {/* SFW Details */}
          <div>
            <Grid container spacing={4} alignItems="center" justifyContent="Center">
              {/* Likes */}
              {characterData !== undefined && characterData.info !== undefined ? characterData.info.likes !== undefined ? <Grid item style={{maxWidth: "25em"}}>
                <Typography variant="h3" fontSize={"2em"} gutterBottom>Likes</Typography>
                {characterData.info.likes.map((like: React.Key | null | undefined | any, idx: number) => (<Typography variant="body1" gutterBottom key={idx}>{like}</Typography>))}
              </Grid> : "" : ""}
              {/* Dislikes */}
              {characterData !== undefined && characterData.info !== undefined ? characterData.info.dislikes !== undefined ? <Grid item style={{maxWidth: "25em"}}>
                <Typography variant="h3" fontSize={"2em"} gutterBottom>Dislikes</Typography>
                {characterData.info.dislikes.map((dislike: React.Key | null | undefined | any, idx: number) => (<Typography variant="body1" gutterBottom key={idx}>{dislike}</Typography>))}
              </Grid> : "" : ""}
            </Grid>
            <div style={{ marginBottom: "2em" }}/>
            <Grid container spacing={1} alignItems="center" justifyContent="Center">
              {/* Traits */}
              {characterData !== undefined && characterData.info !== undefined ? characterData.info.traits !== undefined ? <Grid item style={{maxWidth: "50em"}}>
                <Typography variant="h3" fontSize={"2em"} gutterBottom>Character Traits</Typography>
                {characterData.info.traits.map((trait: React.Key | null | undefined | any, idx: number) => (<Typography variant="body1" gutterBottom key={idx}>{trait}</Typography>))}
              </Grid> : "" : ""}
            </Grid>

            <Grid container spacing={1} alignItems="center" justifyContent="Center">
              {/* Relationships */}
              {relationshipsChars !== undefined ? <Grid item style={{maxWidth: "50em"}}>
                <Typography variant="h3" fontSize={"2em"} gutterBottom>Relationships</Typography>
                {relationshipsChars.map((rel: React.Key | null | undefined | any, idx: number) => (
                  <Typography variant="body1" gutterBottom key={idx}>
                    <span style={{fontWeight: "bold"}}>
                      <a style={{ textDecoration: "none" }} href={`/characters/${rel.id}`}>{rel.shortName}</a>
                    </span> - {rel.description}
                    {/* DBG: {JSON.stringify(rel)} */}
                  </Typography>
                ))}
              </Grid> : ""}
            </Grid>
          </div>
          {/* /SFW Details */}

          <div style={{ marginBottom: "2em" }}/>

          {/* Art of this Character Link */}
          <div style={{margin: "0 auto", textAlign: "center"}}>
            <Button variant="contained" color="secondary" component={Link} to={`/characters/${character}/art${nsfw ? "/nsfw" : ""}`}>View {nsfw ? "NSFW" : ""} Art of {characterData?.shortName}</Button>
          </div>
        </div>
      )}
      <div style={{ marginBottom: "2em" }}/>
    </Layout>
  )
}

Character.defaultProps = {
  nsfw: false
} as Partial<Props>;

export default Character