import React from 'react'
import Layout from '../components/Layout'
import ResponsivePageHeading from '../components/ResponsivePageHeading'

function Other() {
  return (
    <Layout title='Other'>
      <div style={{ marginBottom: "2em" }}/>
      <ResponsivePageHeading variant="h2">Other</ResponsivePageHeading>
    </Layout>
  )
}

export default Other