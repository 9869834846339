import { Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

interface Props {
  children: React.ReactNode,
  variant?: any,
}

function ResponsivePageHeading(props: Props) {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <Typography
      variant={props.variant || "h1"}
      style={{
        fontSize: windowDimensions.width < 1280 ? (props.variant === "h1" ? 0.15 : 0.08)*windowDimensions.width : props.variant === "h1" ? "5em" : props.variant === "h2" ? "4em" : props.variant === "h3" ? "3em" : "2em",
      }}
      gutterBottom
    >
      {props.children}
    </Typography>
  )
}

ResponsivePageHeading.defaultProps = {
  variant: "h1",
} as Partial<Props>;;

export default ResponsivePageHeading