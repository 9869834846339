import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import ResponsivePageHeading from '../components/ResponsivePageHeading'
import { DocumentData, getDocs } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../components/FirebaseStuff";

import { collection, query, where } from "firebase/firestore";

import { Link, useParams } from 'react-router-dom';
import { Backdrop, Button, Chip, Fade, Grid, Modal, Typography } from '@mui/material';
import Page from '../components/Page';
import { Box } from '@mui/system';

interface Props {
  nsfw?: boolean
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: "400",
  // height: "50%",
  // width: "auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CharacterArt(props: Props) {
  let { nsfw } = props;
  let { character } = useParams();
  const [notFound, setNotFound] = React.useState(false);
  const [characterData, setCharacterData] = React.useState<DocumentData>();
  const [charArts, setCharArts] = React.useState<DocumentData[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [modalImage, setModalImage] = React.useState<string>();
  const [modalTitle, setModalTitle] = React.useState<string>();
  const [modalArtists, setModalArtists] = React.useState<DocumentData[]>([]);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {setOpenModal(false); setModalImage(undefined); setModalTitle(undefined); setModalArtists([]);};

  useEffect(() => {
    const characterDocRef = doc(db, "characters", character || "");
    getDoc(characterDocRef).then(docu => {
      if(docu.exists()) {
        if(docu.data().enabled !== undefined && docu.data().enabled === false) {
          setNotFound(true);
          return;
        }
        setCharacterData(docu.data());
        const artsRef = collection(db, "art");
        const q = query(artsRef, where("character", "==", characterDocRef));
        let arts: DocumentData[] = [];
        getDocs(q).then(docs => {docs.forEach(doc => {
          // console.log(doc.data());
          arts.push({id: doc.id, data: doc.data()});
        })}).finally(() => {
          if (!nsfw) {
            arts = arts.filter(art => art.data.sfw === true);
          } else {
            arts = arts.filter(art => art.data.sfw === false);
          }
          setCharArts(arts)
        });
      } else {
        setNotFound(true);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <Layout title={`Art ${characterData !== undefined ? " of " + characterData.shortName : ""}`} chip={nsfw ? "Spicy" : undefined}>
      <div style={{ marginBottom: "2em" }}/>
      {notFound ? <div>
        <Page title="XENPHIRA | Character Not Found" />
        <ResponsivePageHeading variant="h2">Character Not Found</ResponsivePageHeading>
      </div> : characterData !== undefined && characterData.nsfwAllowed === false && nsfw ? <div>Nope!</div> :
      (
        <div>
          <Page title={`XENPHIRA | Art${characterData === undefined ? "" : ": "+characterData?.shortName +(nsfw ? " (Spicy)" : "")}`} />
          <ResponsivePageHeading variant="h2">{characterData !== undefined ? characterData.name : ""}{nsfw ? <Chip label={"NSFW"} variant={"outlined"} color={"warning"} /> : ""}</ResponsivePageHeading>
          {/* <Typography variant="body1" gutterBottom>{JSON.stringify(characterData)}</Typography> */}
          {nsfw ? (
            <Button variant="contained" color="secondary" component={"a"} href={`/characters/${character}/art`}>
              Return to SFW
            </Button>
          ) : ""}
          
          <div style={{ marginBottom: "2em" }}/>

          <Grid container spacing={2}>
            {charArts.map((art, idx) => {
              return <Grid item key={idx}>
              <img onClick={() => {
                let img = art.data.image;
                let artists: DocumentData[] = [];
                let title = "pending";
                setModalImage(img);
                art.data.artists.forEach((a: any) => {
                  getDoc(doc(db, a.path)).then(docu => {
                    if(docu.exists()) {
                      artists.push(docu.data());
                    }
                  }).finally(() => {
                    setModalArtists(artists);
                    getDoc(doc(db, art.data.commission.path)).then((docu: DocumentData) => {
                      if(docu.exists()) {
                        title = docu.data().name;
                      }
                    }).finally(() => {
                      setModalTitle(title);
                      handleOpenModal();
                    })
                  })
                })
              }} src={art.data.image} alt={art.data.name} style={{ width: "10em" }}/>
            </Grid>
            })}
          </Grid>

          <div style={{ marginBottom: "2em" }}/>

          {/* Return to Ref Page */}
          <div style={{margin: "0 auto", textAlign: "center"}}>
            <Button variant="contained" color="secondary" component={Link} to={`/characters/${character}${nsfw ? "/nsfw" : ""}`}>Return to {nsfw ? "NSFW" : ""} reference page of {characterData?.shortName}</Button>
          </div>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={modalStyle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {modalTitle}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              by {modalArtists.map((artist: DocumentData, idx: number, arr: DocumentData[]) => {
                return <span key={idx}><a href={artist.primaryLink} target="_blank" rel="noreferrer">{artist.name}</a>{arr.length - 1 === idx ? "" : ", "}</span>
              })}
            </Typography>
            <img src={modalImage} alt={modalTitle} style={{ width: "20em" }}/>
            <br />
            <a href={modalImage} target={"_blank"} rel="noreferrer">Download</a>
          </Box>
        </Fade>
      </Modal>
    </Layout>
  )
}

CharacterArt.defaultProps = {
  nsfw: false
} as Partial<Props>;;

export default CharacterArt