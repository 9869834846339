import { useEffect } from "react";

interface Props {
  children?: React.ReactNode,
  title: string
}

const Page = (props: Props) => {
  useEffect(() => {
    document.title = props.title || "XENPHIRA";
  }, [props.title]);
  // return props.children;
  return <div />;
};

export default Page;