import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { XenTheme } from './theme';
import { CssBaseline } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './Fonts.css';
import Characters from './pages/Characters';
import About from './pages/About';
import Commissions from './pages/Commissions';
import Other from './pages/Other';
import FourZeroFour from './pages/FourZeroFour';
import Character from './pages/Character';
import CharacterArt from './pages/CharacterArt';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <XenTheme>
      <CssBaseline enableColorScheme />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/characters" element={<Characters />} />
          <Route path="/characters/nsfw" element={<Characters nsfw={true} />} />
          <Route path="/characters/:character" element={<Character />} />
          <Route path="/characters/:character/nsfw" element={<Character nsfw={true} />} />
          <Route path="/characters/:character/art" element={<CharacterArt />} />
          <Route path="/characters/:character/art/nsfw" element={<CharacterArt nsfw={true} />} />
          <Route path="/commissions" element={<Commissions />} />
          <Route path="/other" element={<Other />} />
          <Route path="*" element={<FourZeroFour />} />
        </Routes>
      </BrowserRouter>
    </XenTheme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
