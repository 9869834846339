export {}

export const age = (function() {
  var today = new Date();
  var bday = new Date("May 04, 1997 00:00:00");
  var diff =(today.getTime() - bday.getTime()) / 1000;
  diff /= (60 * 60 * 24);
  return Math.abs(Math.floor(diff/365.25));
})()

export const electronicmail_addr = (function() {
  // needs cleanup, this is dumb
  var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var b = "nopqrstuvwxyzabcdefghijklmNOPQRSTUVWXYZABCDEFGHIJKLM";
  const r = (str: string)  => str.split('')
    .map(char => String.fromCharCode(char.charCodeAt(0) + (char.toLowerCase() < 'n' ? 13 : -13)))
    .join('');
  var x = "==DMi1zYuWKnbOaoyuaYfyJLgORqwSTqh92L";
  x = x.replace(/[a-z]/gi, (c) => b[a.indexOf(c)]);
  // x = x.replace(/[a-zA-Z]/g,function(c){return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);});
  x = x.split("").reverse().join("");
  return atob(x);
})()