import { Typography } from '@mui/material'
import React from 'react'
import Layout from '../components/Layout'
import ResponsivePageHeading from '../components/ResponsivePageHeading'

function FourZeroFour() {
  return (
    <Layout title='404'>
      <div style={{ marginBottom: "2em" }}/>
      <ResponsivePageHeading variant="h2">404</ResponsivePageHeading>
      <Typography variant="body1" gutterBottom>Uh-oh, I couldn't find that page!</Typography>
    </Layout>
  )
}

export default FourZeroFour