import React from 'react'
import Layout from '../components/Layout'
import ResponsivePageHeading from '../components/ResponsivePageHeading'

function Commissions() {
  return (
    <Layout title='Commission Waitlist'>
      <div style={{ marginBottom: "2em" }}/>
      <ResponsivePageHeading variant="h2">Commission Waitlist</ResponsivePageHeading>
    </Layout>
  )
}

export default Commissions